<template>
  <Load :loading="loading" v-if="loading" />
  <div class="checkout" v-else>
    <div class="checkout-container">
      <div class="left">
        <div
          class="address-form"
          v-if="checkOutStatus === 1 || checkOutStatus === 3"
        >
          <div class="title">{{ t("checkout.title") }}</div>
          <div class="sub-title">{{ t("checkout.shippingAddress") }}</div>
          <a-divider />
          <a-form layout="vertical" @submit="onSubmit" :model="modelRef">
            <a-form-item
              :label="t('user.firstName')"
              v-bind="validateInfos.firstName"
            >
              <a-input
                allow-clear
                v-model:value="modelRef.firstName"
                aria-label="First Name Input"
              />
            </a-form-item>
            <a-form-item
              :label="t('user.lastName')"
              v-bind="validateInfos.lastName"
            >
              <a-input
                allow-clear
                v-model:value="modelRef.lastName"
                aria-label="Last Name Input"
              />
            </a-form-item>
            <a-form-item
              :label="t('checkout.country')"
              v-bind="validateInfos.countryOrRegion"
            >
              <a-select
                v-model:value="modelRef.countryOrRegion"
                aria-label="Country / Region Select"
                :options="worldOptions"
                @change="onChange"
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode;
                  }
                "
              />
            </a-form-item>
            <a-form-item
              :label="t('checkout.address')"
              v-bind="validateInfos.address"
            >
              <a-input
                allow-clear
                v-model:value="modelRef.address"
                aria-label="Address Input"
              />
            </a-form-item>
            <a-form-item
              :label="t('checkout.city')"
              v-bind="validateInfos.city"
            >
              <a-input
                allow-clear
                v-model:value="modelRef.city"
                aria-label="City Input"
              />
            </a-form-item>
            <a-form-item
              :label="t('checkout.state')"
              v-bind="validateInfos.stateOrProvince"
            >
              <a-select
                v-model:value="modelRef.stateOrProvince"
                aria-label="State Select"
                :options="stateOptions"
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode;
                  }
                "
              />
            </a-form-item>
            <a-form-item
              :label="t('checkout.zipCode')"
              v-bind="validateInfos.zipCode"
            >
              <a-input
                allow-clear
                v-model:value="modelRef.zipCode"
                aria-label="Zip Code Input"
                :placeholder="placeholder"
              />
            </a-form-item>
            <a-form-item :label="t('user.email')" v-bind="validateInfos.email">
              <a-input
                allow-clear
                v-model:value="modelRef.email"
                aria-label="Email Input"
              />
            </a-form-item>
            <a-form-item
              :label="t('checkout.phoneNumber')"
              v-bind="validateInfos.phoneNumber"
            >
              <a-input
                :addon-before="phoneNumber"
                allow-clear
                aria-label="Phone Number Input"
                v-model:value="modelRef.phoneNumber"
              />
            </a-form-item>
            <a-form-item v-if="user.length !== 0" class="edit-btn">
              <a-button class="use-it" @click="checkOutStatus = 2">{{
                t("user.cancel")
              }}</a-button>
              <a-button html-type="submit" type="primary">{{
                t("checkout.useThisAddress")
              }}</a-button>
            </a-form-item>
            <a-form-item>
              <a-checkbox
                v-if="localStorage.getItem('touristToken')"
                v-model:checked="isAgree"
              >
                {{ t("checkout.agreeContent") }}
              </a-checkbox>
            </a-form-item>
          </a-form>
        </div>
        <div class="change-address" v-else-if="checkOutStatus === 2">
          <div class="header">
            <div class="title">
              <div class="main-title">{{ t("delivery.title") }}</div>
              <div class="sub-title">
                {{ t("checkout.changeAddressTip") }}
              </div>
            </div>
            <div class="add-btn">
              <a-button type="link" @click="newAddress"
                >+ {{ t("checkout.addNew") }}</a-button
              >
            </div>
          </div>
          <a-divider />
          <div class="bottom">
            <div
              :class="[
                'address-card',
                { choose: user[index].isDefault === '1' },
              ]"
              v-for="(address, index) in user"
              :key="address"
              @click="chooseAddress(user[index].addressId)"
            >
              <p class="user" v-for="(item, dex) in user[index]" :key="dex">
                <span v-if="dex !== 'addressId' && dex !== 'isDefault'">{{
                  item
                }}</span>
              </p>
              <a-button
                type="link"
                @click="(e) => editAddress(e, user[index].addressId)"
              >
                <template #icon>
                  <img src="@/assets/icons/edit.png" alt="editIcon" />
                </template>
                <span class="edit">{{ t("checkout.edit") }}</span>
              </a-button>
            </div>
          </div>
        </div>
        <div class="old-address" v-else>
          <div class="title">{{ t("checkout.title") }}</div>
          <p class="user" v-for="(item, index) in chooseUser" :key="index">
            <span v-if="index !== 'addressId' && index !== 'isDefault'">{{
              item
            }}</span>
          </p>
          <a-button type="primary" @click="checkOutStatus = 2">{{
            t("checkout.change")
          }}</a-button>
        </div>
        <div class="payment">
          <div class="title">{{ t("checkout.payWith") }}</div>
          <div class="payment-body">
            <a-radio-group v-model:value="paymentMode">
              <div class="credit-card">
                <div class="card-header" @click="chosePaymentMode(1)">
                  <a-radio :value="1">{{ t("checkout.creditCard") }}</a-radio>
                  <img
                    v-for="item in creditCardImages"
                    :src="item"
                    :key="item"
                    alt="credit card Img"
                    class="credit-img"
                  />
                </div>
                <div class="card-body" v-show="paymentMode === 1">
                  <a-form
                    layout="vertical"
                    :model="paymentRef"
                    @submit="onSubmit"
                  >
                    <a-form-item
                      :label="t('checkout.cardNumber')"
                      v-bind="paymentValidateInfos.cardNumber"
                    >
                      <div
                        id="card-number"
                        aria-label="Card Number Input"
                      ></div>
                    </a-form-item>
                    <a-form-item
                      :label="t('checkout.expirationDate')"
                      v-bind="paymentValidateInfos.expirationDate"
                    >
                      <div id="card-expiry" aria-label="Date picker"></div>
                    </a-form-item>
                    <a-form-item
                      :label="t('checkout.securityCode')"
                      v-bind="paymentValidateInfos.securityCode"
                    >
                      <div id="card-cvc" aria-label="Security Code Input"></div>
                    </a-form-item>
                  </a-form>
                  <div class="message">
                    <a-popover>
                      <template #content>
                        <img
                          src="/images/creditCard/popover.png"
                          alt="creditCard Image"
                        />
                      </template>
                      <exclamation-circleOutlined />
                    </a-popover>
                  </div>
                </div>
              </div>
              <div class="pay-pal">
                <div class="pal-header" @click="chosePaymentMode(0)">
                  <a-radio :value="0">PayPal</a-radio>
                  <img
                    src="@/assets/icons/paypay.png"
                    alt="paypal"
                    style="width: 126px; height: 31px"
                  />
                </div>
                <p class="intro" v-show="paymentMode === 0">
                  {{ t("checkout.paymentIntro") }}
                </p>
              </div>
              <div class="transfer">
                <div class="pal-header" @click="chosePaymentMode(2)">
                  <a-radio :value="2">{{ t("checkout.transfer") }}</a-radio>
                  <img
                    class="transfer-img"
                    src="@/assets/icons/transfer.svg"
                    alt="transfer logo"
                  />
                </div>
                <div class="transfer-content" v-show="paymentMode === 2">
                  <p>{{ t("checkout.transferMessage") }}</p>
                  <span class="transfer-card-number">ES-9201 - 8240 - 2687 - 0201- 770438</span>
                </div>
              </div>
            </a-radio-group>
          </div>
        </div>
        <order-items />
      </div>
      <Summary :loading="processing" :disabled="disabled" @submit="onSubmit" />
    </div>
  </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { useForm } from '@ant-design-vue/use';
import {
  Divider,
  Form,
  Input,
  Select,
  Button,
  Radio,
  Popover,
  Collapse,
  DatePicker,
  Checkbox,
  message,
} from 'ant-design-vue';
import {
  defineComponent,
  reactive,
  ref,
  computed,
  onBeforeMount,
  onMounted,
} from 'vue';
import worldOptions from '@/utils/world-area';
import {
  addAddress,
  changeAddress,
  getAddress,
  payToken,
} from '@/services/user';
import Cookies from 'js-cookie';
import type { Address } from '@/services/interface/response';
import { useRoute, useRouter } from 'vue-router';
import { Alert } from '@/components/Alert/index';
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import OrderItems from '@/components/checkout/OrderItems.vue';
import Summary from '@/components/checkout/Summary.vue';
import { useStore } from 'vuex';
import {
  cardPayCancel,
  cardPaySuccess,
  payOrder,
  VisitorCardPayCancel,
  visitorCardPaySuccess,
  VisitorPayOrder,
} from '@/services/pay';
import Load from '@/components/common/Load.vue';
import { ModalFuncProps } from 'ant-design-vue/lib/modal';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElementLocale,
  StripeElementChangeEvent,
} from '@stripe/stripe-js';

interface OrderItem {
  img: string;
  description: string;
  qty: number;
  price: number;
}

interface StateOption {
  label: string;
  value: string;
}

type PayMode = 0 | 1 | 2;

interface Card {
  cardNumber: string;
  expirationDate: moment.Moment;
  securityCode: string;
}

const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default defineComponent({
  name: 'Checkout',
  components: {
    OrderItems,
    Summary,
    Load,
    'exclamation-circleOutlined': ExclamationCircleOutlined,
    ...importComponents(
      Divider,
      Form,
      Form.Item,
      Input,
      Select,
      Button,
      Radio,
      Radio.Group,
      Popover,
      Collapse,
      Collapse.Panel,
      DatePicker.MonthPicker,
      Popover,
      Checkbox,
    ),
  },
  setup() {
    const { dispatch, state } = useStore();
    const loading = ref(true);
    const router = useRouter();
    const route = useRoute();
    const processing = ref(false);
    const placeholder = ref('');
    const phoneNumber = ref('');
    const { t, locale } = useI18n();

    // 支付方式 0：paypal 1：信用卡
    const paymentMode = ref<PayMode>(1);

    const disabled = ref(true);
    // 游客是否同意自动注册
    const isAgree = ref(false);

    // 添加地址表单数据
    const modelRef = reactive({
      firstName: '',
      lastName: '',
      countryOrRegion: '',
      address: '',
      city: '',
      stateOrProvince: '',
      zipCode: '',
      email: '',
      phoneNumber: '',
      addressId: '',
    });

    const paymentRef = reactive<Card>({
      cardNumber: '',
      expirationDate: moment(),
      securityCode: '',
    });

    const stateOptions = ref<StateOption[]>([]);
    // 州选项
    const caseState = (index: number) => worldOptions[index].state.map((arr) => ({
      label: arr.name,
      value: arr.name,
    }));

    // 当切换国家的时候 Zip Code和电话号码前缀(国际区号)变换
    const onChange = (value: string) => {
      switch (value) {
        case 'ES':
          phoneNumber.value = 'ES +34';
          stateOptions.value = caseState(0);
          break;
        case 'IT':
          phoneNumber.value = 'IT +39';
          stateOptions.value = caseState(1);
          break;
        case 'FR':
          phoneNumber.value = 'FR +33';
          stateOptions.value = caseState(2);
          break;
        case 'DE':
          phoneNumber.value = 'DE +49';
          stateOptions.value = caseState(3);
          break;
        case 'US':
          phoneNumber.value = 'US +1';
          stateOptions.value = caseState(4);
          break;
        case 'GB':
          phoneNumber.value = 'GB +44';
          stateOptions.value = caseState(5);
          break;
        case 'NL':
          phoneNumber.value = 'NL +31';
          stateOptions.value = caseState(6);
          break;
        case 'IE':
          phoneNumber.value = 'IE +353';
          stateOptions.value = caseState(7);
          break;
        default:
          phoneNumber.value = '';
      }
      modelRef.stateOrProvince = '';
      modelRef.address = '';
      modelRef.city = '';
    };

    // 表单校验提示
    const rulesRef = reactive({
      firstName: [
        {
          required: true,
          message: t('checkout.enterA') + t('user.firstName'),
        },
      ],
      lastName: [
        {
          required: true,
          message: t('checkout.enterA') + t('user.lastName'),
        },
      ],
      countryOrRegion: [
        {
          required: true,
          message: t('checkout.pleaseChoose') + t('checkout.country'),
        },
      ],
      address: [
        {
          required: true,
          message: t('checkout.enterAn') + t('checkout.address'),
        },
      ],
      city: [
        {
          required: true,
          message: t('checkout.enterA') + t('checkout.city'),
        },
      ],
      stateOrProvince: [
        {
          required: true,
          message: t('checkout.pleaseChoose') + t('checkout.state'),
        },
      ],
      zipCode: [
        {
          required: true,
          message: t('checkout.pleaseEnter') + t('checkout.zipCode'),
        },
      ],
      email: [
        {
          validator: (rule: ValidationRule, value: string) => {
            if (value === '') {
              return Promise.reject(new Error(t('contactUs.isRequired')));
            }
            if (!EMAIL_PATTERN.test(value)) {
              return Promise.reject(new Error(t('user.emailMessage')));
            }
            return Promise.resolve();
          },
          required: true,
          trigger: 'change',
        },
      ],
      phoneNumber: [
        {
          validator: (rule: ValidationRule, value: string) => {
            if (value === '') {
              return Promise.reject(new Error(t('checkout.phoneMessage')));
            }
            if (/[^\d]/g.test(value)) {
              const empty = /[^\d]/g;
              modelRef.phoneNumber = modelRef.phoneNumber.replace(empty, '');
            }
            return Promise.resolve();
          },
          required: true,
          trigger: 'change',
        },
      ],
    });

    const paymentRule = reactive({
      cardNumber: [
        {
          required: true,
          message:
            t('checkout.pleaseEnter')
            + t('checkout.correct')
            + t('checkout.cardNumber'),
        },
      ],
      expirationDate: [
        {
          required: true,
          message: t('checkout.pleaseSelect') + t('checkout.expirationDate'),
        },
      ],
      securityCode: [
        {
          required: true,
          message: t('checkout.pleaseEnter') + t('checkout.securityCode'),
        },
      ],
    });

    const { validate, validateInfos } = useForm(modelRef, rulesRef);

    const { validate: paymentValidate, validateInfos: paymentValidateInfos } = useForm(paymentRef, paymentRule);

    const creditCardImages = [
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/visa@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/masterCard@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/americanExpress@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/jCB@2x.png',
      // 'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/unionPay@2x.png',
    ];
    // 0: 老客户, 1: 新客户, 2: 选择地址, 3: 编辑地址。
    const checkOutStatus = ref(1);

    // 存放所有的地址数据的数组
    const user: Address[] = reactive([]);
    // 存放默认显示的地址
    const chooseUser = computed(
      () => user.filter((item) => item.isDefault === '1')[0],
    );

    let stripe: Stripe;
    let cardNumber: StripeCardNumberElement;
    let cardExpiry: StripeCardExpiryElement;
    let cardCvc: StripeCardCvcElement;

    const createCard = async () => {
      stripe = (await loadStripe(process.env.VUE_APP_PAY_KEY)) as Stripe;
      const elements = stripe.elements({
        locale: locale.value.split('_')[0] as StripeElementLocale,
      });
      cardNumber = elements.create('cardNumber', {
        showIcon: true,
        iconStyle: 'solid',
      });
      cardExpiry = elements.create('cardExpiry');
      cardCvc = elements.create('cardCvc', {
        placeholder: 'CVV',
      });

      setTimeout(() => {
        cardNumber.mount('#card-number');
        cardExpiry.mount('#card-expiry');
        cardCvc.mount('#card-cvc');
      }, 3000);

      const getError = (event: StripeElementChangeEvent) => {
        disabled.value = event.empty;
        if (event.error) {
          const modal = Alert.error(event.error.message);
          setTimeout(() => {
            modal.destroy();
          }, 2000);
        }
      };
      cardNumber.on('change', getError);
      cardExpiry.on('change', getError);
      cardCvc.on('change', getError);
    };

    createCard();

    onBeforeMount(async () => {
      // 已登录的话加载直接获取用户地址信息
      if (Cookies.get('token')) {
        // 游客选择转账支付登录成功之后要获取一下购物车才能提交
        if (route.query.f) {
          dispatch('getCartItems');
          if (Cookies.get('address')) {
            Object.assign(modelRef, JSON.parse(Cookies.get('address') as string));
          }
        }
        try {
          const res = await getAddress();
          user.push(...res.data);
          if (user.length > 0) {
            // 如果该用户添加过地址，则直接显示默认地址
            checkOutStatus.value = 0;
          }
          loading.value = false;
          if (route.params.errorCode) {
            let modal: {
              destroy: () => void;
              update: (newConfig: ModalFuncProps) => void;
            };
            switch (route.params.errorCode) {
              case '51055':
                modal = Alert.error(t('checkout.paymentFailed'));
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                break;
              default:
                modal = Alert.error(t('checkout.paymentFailed'));
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                break;
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else if (!localStorage.getItem('touristToken')) {
        // 解决游客购完物，到订单完成页面清掉游客token，再次购买checkout没有游客token会校验不通过
        localStorage.setItem(
          'touristToken',
          Date.parse(new Date().toString())
            + Math.random().toFixed(2).toString(),
        );
      }
      loading.value = false;
    });

    // 抽取success弹窗代码
    const alertFunc = (value: string) => {
      const modal = Alert.success(value);
      setTimeout(() => {
        modal.destroy();
        router.push('/checkout');
        checkOutStatus.value = 0;
      }, 2000);
    };

    const errorModal = (error: string) => {
      const modal = Alert.error(t(error));
      setTimeout(() => {
        modal.destroy();
      }, 2000);
      processing.value = false;
    };

    const payWithCard = async (
      clientSecret: string,
      orderNumber: string,
      userId?: number,
    ) => {
      stripe
        .confirmCardPayment(clientSecret, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          receipt_email: sessionStorage.getItem('userEmail') as string,
          // eslint-disable-next-line @typescript-eslint/camelcase
          payment_method: {
            card: cardNumber,
          },
        })
        .then(async (res) => {
          if (res.paymentIntent?.status) {
            let response;
            if (userId) {
              response = await visitorCardPaySuccess(orderNumber, userId);
            } else {
              response = await cardPaySuccess(orderNumber);
            }
            if (response.success) {
              window.location.replace(`/order-complete/${orderNumber}`);
              // 清空游客购物车
              dispatch('removeVisitorCart');
            } else {
              errorModal(response.message);
            }
          } else {
            let response;
            if (localStorage.getItem('touristToken')) {
              response = await VisitorCardPayCancel(orderNumber);
            } else {
              response = await cardPayCancel(orderNumber);
              if (response.success) {
                // 新用户添加地址已成功，信用卡支付失败，无需再一次添加地址
                if (checkOutStatus.value === 1) {
                  checkOutStatus.value = 0;
                  getAddress().then((re) => {
                    Object.assign(user, re.data);
                  });
                }
              }
            }
            processing.value = false;
            const dialogFun = (value: string) => {
              Alert.error(t(value), true);
            };
            // 支付失败，取消订单，给一些友好的提示，失败原因：https://stripe.com/docs/error-codes/card-declined，根据decline_code查找
            if (res.error?.type === 'api_connection_error') {
              dialogFun('shipping.netWorkError');
            }
            switch (res.error?.decline_code) {
              case 'insufficient_funds':
              case 'card_velocity_exceeded':
              case 'invalid_amount':
              case 'withdrawal_count_limit_exceeded':
                // 余额不足
                dialogFun('shipping.insufficient');
                break;
              case 'fraudulent':
              case 'merchant_blacklist':
              case 'invalid_account':
              case 'new_account_information_available':
                // 可能有欺诈行为
                dialogFun('shipping.workingError');
                break;
              case 'call_issuer':
              case 'do_not_honor':
              case 'do_not_try_again':
              case 'generic_decline':
              case 'no_action_taken':
              case 'revocation_of_all_authorizations':
              case 'revocation_of_authorization':
              case 'security_violation':
              case 'service_not_allowed':
              case 'stop_payment_order':
              case 'transaction_not_allowed':
              case 'try_again_later':
              case 'reenter_transaction':
              case 'not_permitted':
                // 由于未知原因,该卡已被拒绝,应联系发卡人以获取更多信息
                dialogFun('shipping.unknownReason');
                break;
              case 'expired_card':
                // 卡过期
                dialogFun('shipping.expired');
                break;
              case 'incorrect_number':
              case 'invalid_number':
                // 卡号不正确
                dialogFun('shipping.cardNumber');
                break;
              case 'pin_try_exceeded':
                // 已超出允许的 PIN 尝试次数
                dialogFun('shipping.pinTries');
                break;
              case 'incorrect_pin':
              case 'invalid_pin':
                // PIN 不正确
                dialogFun('shipping.pinError');
                break;
              case 'offline_pin_required':
              case 'online_or_offline_pin_required':
                // 需要输入PIN进行支付
                dialogFun('shipping.pinEnter');
                break;
              case 'invalid_cvc':
              case 'incorrect_cvc':
                // CVV不正确
                dialogFun('shipping.cvc');
                break;
              case 'lost_card':
              case 'pickup_card':
              case 'restricted_card':
              case 'stolen_card':
                // 可能报告丢失或被盗
                dialogFun('shipping.workingError');
                break;
              case 'processing_error':
                // 处理卡时发生错误，尝试重试或者稍后
                dialogFun('shipping.processingError');
                break;
              case 'card_not_supported':
                // 该卡不支持此类购买
                dialogFun('shipping.notSupport');
                break;
              case 'approve_with_id':
              case 'issuer_not_available':
                // 付款无法授权，应再次尝试付款，如果仍无法处理，客户需要联系其发卡人。
                dialogFun('shipping.authorized');
                break;
              case 'invalid_expiry_month':
                // 到期月无效。
                dialogFun('shipping.month');
                break;
              case 'invalid_expiry_year':
                // 到期年无效。
                dialogFun('shipping.year');
                break;
              case 'incorrect_zip':
                // 邮政编码/邮政编码不正确。
                dialogFun('shipping.codeNum');
                break;
              case 'currency_not_supported':
                // 该卡不支持指定的货币。
                dialogFun('shipping.currency');
                break;
              case 'authentication_required':
                // 该卡验证失败。
                dialogFun('shipping.verification');
                break;
              case 'duplicate_transaction':
                // 最近提交相同交易。
                dialogFun('shipping.submitted');
                break;
              case 'testmode_decline':
                // 使用了条纹测试卡号。
                dialogFun('shipping.genuine');
                break;
              default:
                // 其他
                dialogFun(res.error?.message as string);
                break;
            }
          }
        });
    };

    // 提取支付token和支付订单接口
    const pay = async (addressId?: string) => {
      try {
        const res = await payToken();
        processing.value = true;
        let response;
        if (addressId) {
          const data = {
            addressId,
            ids: state.cartStore.cartIdArray,
            signature: res.data,
            payMode: paymentMode.value,
            via: 'pc',
          };
          response = await payOrder(data);
        } else {
          Cookies.set('address', modelRef);
          const data = {
            ...modelRef,
            signature: res.data,
            cartDTOList: state.ShoppingCart,
            isRegistration: isAgree.value,
            payMode: paymentMode.value,
            touristToken: localStorage.getItem('touristToken') as string,
            via: 'pc',
          };
          response = await VisitorPayOrder(data);
        }
        if (response.success && paymentMode.value === 0) { // PayPal
          window.location.href = response.data.payUrl as string;
          if (localStorage.getItem('touristToken')) {
            localStorage.removeItem('touristToken');
          }
        } else if (response.success && paymentMode.value === 1) { // 卡
          if (localStorage.getItem('touristToken')) {
            payWithCard(
              response.data.clientSecret as string,
              response.data.orderNumber as string,
              response.data.userId,
            );
          } else {
            payWithCard(
              response.data.clientSecret as string,
              response.data.orderNumber as string,
            );
          }
        } else if (response.success && paymentMode.value === 2) { // 转账
          if (Cookies.get('token')) {
            dispatch('cleanCart');
            dispatch('removeVisitorCart');
            router.push(`/user/orders-details?${response.data.orderNumber}`);
          } else {
            // 游客想转账支付，让他去注册登录
            router.push('/login?form=payment');
          }
        } else if (response.code === 21018) {
          errorModal('checkout.inventoryShortage');
        } else if (response.code === 21010) {
          errorModal('checkout.emailRegistered');
          isAgree.value = false;
        } else if (response.code === 33000) {
          errorModal(response.message);
          processing.value = false;
        } else {
          // errorModal('checkout.addressMessage');
          errorModal(response.message);
          processing.value = false;
        }
      } catch (e) {
        processing.value = false;
      }
    };
    // const pay = (addressId?: string) => payToken()
    //   .then(async (res) => {
    //     processing.value = true;
    //     let response;
    //     if (addressId) {
    //       // 游客选择转账支付登录成功之后要获取一下购物车才能提交
    //       if (route.query.f) {
    //         dispatch('getCartItems')
    //           .then(() => {
    //             // console.log(state.cartIdArray);
    //           });
    //       }
    //       const data = {
    //         addressId,
    //         ids: state.cartStore.cartIdArray,
    //         signature: res.data,
    //         payMode: paymentMode.value,
    //         via: 'pc',
    //       };
    //       response = await payOrder(data);
    //     } else {
    //       const data = {
    //         ...modelRef,
    //         signature: res.data,
    //         cartDTOList: state.ShoppingCart,
    //         isRegistration: isAgree.value,
    //         payMode: paymentMode.value,
    //         touristToken: localStorage.getItem('touristToken') as string,
    //         via: 'pc',
    //       };
    //       response = await VisitorPayOrder(data);
    //     }
    //     if (response.success && paymentMode.value === 0) { // PayPal
    //       window.location.href = response.data.payUrl as string;
    //       if (localStorage.getItem('touristToken')) {
    //         localStorage.removeItem('touristToken');
    //       }
    //     } else if (response.success && paymentMode.value === 1) { // 卡
    //       if (localStorage.getItem('touristToken')) {
    //         payWithCard(
    //           response.data.clientSecret as string,
    //           response.data.orderNumber as string,
    //           response.data.userId,
    //         );
    //       } else {
    //         payWithCard(
    //           response.data.clientSecret as string,
    //           response.data.orderNumber as string,
    //         );
    //       }
    //     } else if (response.success && paymentMode.value === 2) { // 转账
    //       if (Cookies.get('token')) {
    //         dispatch('cleanCart');
    //         dispatch('removeVisitorCart');
    //         router.push(`/user/orders-details?${response.data.orderNumber}`);
    //       } else {
    //         // 游客想转账支付，让他去注册登录
    //         router.push('/login?form=payment');
    //       }
    //     } else if (response.code === 21018) {
    //       errorModal('checkout.inventoryShortage');
    //     } else if (response.code === 21010) {
    //       errorModal('checkout.emailRegistered');
    //       isAgree.value = false;
    //     } else {
    //       errorModal('checkout.addressMessage');
    //     }
    //   })
    //   .catch(() => {
    //     processing.value = false;
    //   });

    // 表单提交按钮 & 该用户第一次添加地址时此按钮不出现直接去支付
    const onSubmit = () => {
      if (checkOutStatus.value === 1 || checkOutStatus.value === 3) {
        validate().then(async () => {
          processing.value = true;
          if (checkOutStatus.value === 1 && Cookies.get('token')) {
            // 新增 调新增地址接口
            try {
              const res = await addAddress(modelRef);
              if (res.success && user.length !== 0) {
                const { data } = await getAddress();
                Object.assign(user, data);
                alertFunc(t('checkout.addSuccessfully'));
                processing.value = false;
              } else if (!res.success) {
                const modal = Alert.error(res.message);
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                processing.value = false;
              } else {
                pay(res.data).catch(() => {
                  processing.value = false;
                });
              }
            } catch (err) {
              return err;
            }
          } else if (checkOutStatus.value === 1 && !Cookies.get('token')) {
            pay();
          } else if (checkOutStatus.value === 3) {
            // 编辑 调更新修改接口
            try {
              const res = await changeAddress(modelRef, modelRef.addressId);
              if (res.success) {
                const { data } = await getAddress();
                Object.assign(user, data);
                alertFunc(t('checkout.updateSuccessfully'));
                processing.value = false;
              } else {
                const modal = Alert.error(res.message);
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                processing.value = false;
              }
            } catch (err) {
              return err;
            }
            processing.value = false;
          }
          return null;
        });
      }
      if (checkOutStatus.value === 0) {
        const defaultUser = user.find(({ isDefault }) => isDefault === '1');
        pay(defaultUser?.addressId ?? '').catch(() => {
          processing.value = false;
        });
      }
    };

    const newAddress = () => {
      // 清空点击进入过编辑状态的地址
      Object.assign(modelRef, {
        firstName: '',
        lastName: '',
        countryOrRegion: '',
        address: '',
        city: '',
        stateOrProvince: '',
        email: '',
        phoneNumber: '',
        zipCode: '',
      });
      checkOutStatus.value = 1;
    };

    const editAddress = (e: MouseEvent, addressId: string) => {
      // edit按钮在div中，避免点击edit的同时也点到div
      e.stopPropagation();
      window.scrollTo(0, 0);
      checkOutStatus.value = 3;
      const editUser = user.filter((item) => item.addressId === addressId)[0];
      Object.assign(modelRef, editUser);
    };

    const chooseAddress = async (addressId: string) => {
      // 选同一个 不请求
      if (
        addressId === user.filter((item) => item.isDefault === '1')[0].addressId
      ) {
        checkOutStatus.value = 0;
        return;
      }

      // 选择地址之后被选中的地址变成默认地址
      changeAddress(
        user.filter((item) => item.addressId === addressId)[0],
        addressId,
      ).then((res) => {
        if (res.success) {
          checkOutStatus.value = 0;
          window.scrollTo(0, 0);
          getAddress().then((re) => {
            Object.assign(user, re.data);
          });
        } else {
          const modal = Alert.error(res.message);
          setTimeout(() => {
            modal.destroy();
          }, 2000);
        }
      });
    };

    const chosePaymentMode = (mode: PayMode) => {
      paymentMode.value = mode;
      if (mode === 0 || mode === 2) {
        disabled.value = false;
      }
    };

    onMounted(() => {
      // 游客选择转账支付登录成功之后要获取一下购物车才能提交
      if (route.query.f) {
        paymentMode.value = 2;
        disabled.value = false;
        dispatch('getCartItems');
      }
      if (Cookies.get('token') && state.cartStore.cartItems.length === 0) {
        dispatch('getCartItems');
      } else if (!Cookies.get('token') && state.cartNumber !== 0) {
        dispatch('getVisitorCart');
      }
    });

    return {
      loading,
      modelRef,
      rulesRef,
      validateInfos,
      onSubmit,
      checkOutStatus,
      user,
      editAddress,
      newAddress,
      chooseAddress,
      worldOptions,
      chooseUser,
      processing,
      stateOptions,
      onChange,
      placeholder,
      phoneNumber,
      t,
      paymentRef,
      paymentValidate,
      paymentValidateInfos,
      creditCardImages,
      ExclamationCircleOutlined,
      paymentMode,
      chosePaymentMode,
      disabled,
      isAgree,
      localStorage,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";
.loading {
  flex: 1;
  border-bottom: none;
}

.checkout {
  background-color: $container-background-color;
  border-bottom: 3px solid #e1e1e1;
  position: relative;
  z-index: 1;
  .checkout-container {
    @include main-container();
    width: 1280px;
    padding: 50px 0 131px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 893px;
      min-width: 446.5px;
      .address-form {
        background-color: #ffffff;
        border-radius: 12px;
        box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.16);
        padding: 35px 0 49px 39px;
        .sub-title {
          font-size: 20px;
          font-weight: 600;
          color: #1c1c1c;
          padding: 13px 0;
        }
        .edit-btn {
          padding-top: 30px;
          .ant-form-item-children {
            display: flex;
            justify-content: space-between;
            .use-it {
              height: 44px;
              font-weight: bold;
              color: #545454;
              font-size: 14px;
              background: #f5f5f5;
              border-radius: 4px;
              border: none;
            }
            button {
              display: block;
            }
            .ant-btn,
            .ant-btn-primary {
              width: 180px;
              margin: unset;
            }
            .ant-checkbox-wrapper {
              padding-top: 7px;
              .ant-checkbox + span {
                font-weight: 400;
                color: #545454;
              }
            }
          }
        }
      }
      .change-address {
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.16);
        border-radius: 12px;
        .header {
          display: flex;
          padding: 37px 35px 18px;
          .sub-title {
            font-size: 14px;
            font-weight: 400;
            color: #545454;
            line-height: 30px;
          }
          .add-btn {
            flex: 1;
            flex-direction: column;
            align-items: flex-end;
            display: flex;
            justify-content: flex-end;
            .ant-btn.ant-btn-link {
              font-size: 16px;
              font-weight: 500;
              color: $theme-color;
              &:hover,
              &:focus {
                color: $theme-color;
              }
            }
          }
        }
        .bottom {
          padding: 35px 37px 61px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .address-card {
            width: 45.4%;
            border-radius: 8px;
            border: 2px solid #bfbfbf;
            padding: 10px 28px 10px;
            margin-bottom: 35px;
            &:hover {
              border: 2px solid $theme-color;
            }
            .user {
              font-size: 14px;
              font-weight: 400;
              color: #545454;
              margin: unset;
              line-height: 26px;
              cursor: pointer;
            }
            .ant-btn.ant-btn-link {
              padding: 15px 0 40px;
              color: $theme-color;
              img {
                width: 22px;
                height: 22px;
              }
              .edit {
                font-size: 16px;
                display: inline-block;
                padding-left: 8px;
              }
              &:hover,
              &:focus {
                color: $theme-color;
              }
            }
          }
          .choose {
            border: 2px solid $theme-color;
          }
        }
        .ant-divider.ant-divider-horizontal {
          background-color: #f5f5f5;
          width: 100%;
          min-width: 100%;
        }
      }
      .title {
        font-size: 25px;
        font-weight: bold;
        color: $theme-color;
        margin-bottom: 20px;
      }
      .old-address {
        background-color: #ffffff;
        border-radius: 12px;
        width: 893px;
        padding: 35px 0 109px 35px;
        .user {
          font-size: 14px;
          font-weight: 400;
          color: #545454;
          margin: unset;
          padding-bottom: 5px;
        }
      }
      .ant-btn.ant-btn-primary {
        font-size: 16px;
        font-weight: bold;
        width: 180px;
        height: 44px;
        border-radius: 4px;
        margin-top: 23px;
        background: $theme-color;
        border-color: $theme-color;
      }
    }
  }
  .payment {
    margin: 30px 0;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.16);
    background: #fff;
    border: 1px solid #f0f0f0;
    .ant-form-item-control {
      padding: 12px;
      border: 1px solid #d7d7d7;
    }
    .title {
      @include card-title();
      margin-bottom: 0 !important;
    }
    .payment-body {
      display: flex;
      flex-direction: column;
      padding: 24px;
      .ant-radio-wrapper {
        span {
          font-size: 18px;
          font-weight: 500;
          color: #1c1c1c;
        }
        .ant-radio-checked > .ant-radio-inner {
          border-color: $theme-color;
          &::after {
            background-color: $theme-color;
          }
        }
      }
      .ant-radio-wrapper-checked {
        span {
          font-weight: 600;
          color: $theme-color;
        }
      }
      .ant-collapse-borderless {
        background-color: unset;
        border: none;
      }
      .ant-collapse {
        border: none;
        .above {
          .ant-collapse-header {
            font-weight: 500;
            color: #1c1c1c;
            font-size: 18px;
          }
        }
      }
      .ant-collapse-borderless > .ant-collapse-item,
      .ant-collapse > .ant-collapse-item,
      .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
        border-bottom: unset;
      }
      .intro {
        margin: 15px 0 12px 22px;
      }
      .credit-img {
        width: 50px;
        padding-right: 10px;
      }
      #card-element {
        border: 1px solid;
        padding: 12px;
        margin: 22px;
      }
      .credit-card {
        padding-bottom: 30px;
        .card-header {
          cursor: pointer;
        }
        .card-body {
          display: flex;
          .message {
            display: flex;
            align-items: flex-end;
            padding: 22px 20px;
          }
        }
      }
      .pay-pal, .transfer{
        .pal-header {
          cursor: pointer;
          display: flex;
          align-items: flex-end;
          img{
            height: 28px;
            margin-left: 30px;
            margin-bottom: 6px;
            &.transfer-img{
              height: 25px;
            }
          }
        }
      }
      .pay-pal{
        margin-bottom: 28px;
      }
      .transfer{
        .transfer-content{
          margin-top: 10px;
          padding-left: 26px;
          .transfer-card-number{
            display: inline-block;
            background: #FDFDFD;
            border-radius: 3px;
            border: 1px solid #E6EAEE;
            padding: 12px;
            font-size: 15px;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            color: #4C475A;
          }
        }
      }
    }
  }
  // ui组件样式
  .ant-divider.ant-divider-horizontal {
    width: 595px;
    min-width: 595px;
    background-color: #ededed;
    margin: unset;
  }

  .ant-form.ant-form-vertical {
    padding-top: 16px;
    width: 50%;
    .ant-select .ant-select-single .ant-select-show-arrow :hover {
      border: 1px solid red !important;
    }
    .ant-form-item-control {
      border-radius: 4px;
    }
    .ant-select-focus:not(.ant-select-disabled)
      .ant-select-single:not(.ant-select-customize)
      .ant-select-selector {
      border-color: red !important;
    }
    //更改的部分-dev-tokyo-end
    .ant-row.ant-form-item {
      padding: unset;
      margin: unset;
      .ant-col.ant-form-item-label {
        font-size: 14px;
        font-weight: 400;
        color: #545454;
      }
      .ant-col.ant-form-item-control-wrapper {
        margin-bottom: 10px;
        .ant-input-affix-wrapper {
          border-radius: 4px;
          border: 1px solid #d7d7d7;
          line-height: 2.2;
          &:hover {
            border-color: $theme-color;
          }
        }
        .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
          border-color: $theme-color;
          box-shadow: $box-shadow;
        }
        .ant-select-selector {
          height: 40px;
          .ant-select-selection-item {
            line-height: 40px;
          }
        }
      }
    }
  }
  .ant-affix {
    top: 157px !important;
    .ant-anchor-wrapper {
      background-color: unset;
      overflow: unset;
      .ant-anchor {
        .ant-anchor-ink {
          display: none;
        }
      }
    }
  }
  .ant-anchor-wrapper {
    background-color: unset;
  }
}
</style>
