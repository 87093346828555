<template>
  <a-anchor :affix="false">
    <div class="right-summary">
      <div class="title">{{ t("cart.summary") }}</div>
      <div class="sub-total">
        <div class="label">{{ t("cart.subtotal") }}</div>
        <div class="price">
          {{ t("global.currency") }}{{ cartPrice.marketPrice }}
        </div>
      </div>
      <div class="saving">
        <div class="label">{{ t("cart.saving") }}</div>
        <div class="price">
          {{ t("global.currency") }}{{ cartPrice.reducedPrice }}
        </div>
      </div>
      <a-divider />
      <div class="total">
        <div class="label">{{ t("cart.total") }}</div>
        <div class="price">
          {{ t("global.currency") }}{{ cartPrice.sellPrice }}
        </div>
      </div>
      <a-button
        type="primary"
        :loading="loading"
        :disabled="disabled"
        @click="onSubmit"
        >{{ t("cart.submit") }}</a-button
      >
      <div class="sign">
        <GlobalSignSeal />
        <div class="right-img">
          <img
            src="/images/checkout/checkout.svg"
            alt="responsible recycling"
          />
        </div>
      </div>
    </div>
  </a-anchor>
</template>

<script lang='ts'>
import importComponents from '@/utils/import-components';
import { defineComponent, computed, onBeforeMount } from 'vue';
import { Divider, Anchor, Button } from 'ant-design-vue';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import { cartItem } from '@/services/cart';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import GlobalSignSeal from '@/components/common/GlobalSignSeal.vue';

export default defineComponent({
  name: 'Summary',
  components: {
    GlobalSignSeal,
    ...importComponents(Anchor, Divider, Button),
  },
  props: {
    loading: Boolean,
    disabled: {
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const { state } = useStore();

    const { t } = useI18n();

    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        const res = await cartItem();
        // eslint-disable-next-line no-unused-expressions
        res.code === 51101 ? router.push('/404') : null;
      }
    });

    const cartPrice = computed(() => state.cartStore.cartPrice);

    const onSubmit = () => ctx.emit('submit');

    return {
      cartPrice,
      onSubmit,
      t,
    };
  },
});
</script>

<style lang='scss' scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";
.right-summary {
  width: 357px;
  height: 358px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.16);
  border-radius: 12px;
  padding: 37px;
  .sub-total,
  .saving,
  .total {
    display: flex;
    justify-content: space-between;
  }
  .ant-divider.ant-divider-horizontal {
    width: 126%;
    min-width: 126%;
    background-color: #ededed;
    right: 37px;
    margin: unset;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    color: $theme-color;
    line-height: 24px;
    padding-bottom: 20px;
  }
  .sub-total,
  .saving {
    align-items: center;
    .label {
      font-size: 14px;
      font-weight: 500;
      color: #545454;
    }
    .price {
      font-size: 18px;
      font-weight: 500;
      color: #1c1c1c;
    }
  }
  .saving {
    line-height: 55px;
  }
  .total {
    margin-top: 25px;
    align-items: center;
    .label {
      line-height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #545454;
    }
    .price {
      line-height: 24px;
      font-weight: 800;
      color: #1c1c1c;
      font-size: 20px;
    }
  }
  .ant-btn.ant-btn-primary {
    width: -webkit-fill-available;
    font-size: 14px;
    font-weight: bold;
    border-color: $theme-color;
    height: 44px;
    background: $theme-color;
    border-radius: 4px;
    margin-top: 38px;
  }
  .sign {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .right-img {
      width: 98px;
      height: 41px;
      img{
        width:100%;
      }
    }
  }
}
</style>
