<template>
  <div>
    <span id="ss_img_wrapper_gmogs_image_90-35_en_dblue">
      <a href="https://www.globalsign.com/" target="_blank" title="GlobalSign Site Seal" rel="nofollow">
        <img alt="SSL" border=0 id="ss_img" src="//seal.globalsign.com/SiteSeal/images/gs_noscript_90-35_en.gif">
      </a>
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GlobalSignSeal',
  mounted() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//seal.globalsign.com/SiteSeal/gmogs_image_90-35_en_dblue.js';
    this.$el.appendChild(script);
  },
});
</script>
