<template>
  <div class="order-items">
    <div class="title">{{ t('checkout.orderItem') }}</div>
    <div class="order-body">
      <div
        v-for="(item, index) in cartLists"
        :key="item"
        :class="['row', { hr: index !== cartLists.length - 1 }]"
      >
        <div class="img">
          <img
            :src="`${item.goodsPic}?x-oss-process=image/resize,w_75,h_75`"
            :alt="item.goodsName"
          />
        </div>
        <div class="text">
          <div class="description">
            <div class="name">{{ item.goodsName }}</div>
            <div
              class="detail"
              v-if="JSON.stringify(item.skuAttr) !== '{}' || item.skuAttr"
            >
              <span v-for="sku in Object.values(item.skuAttr)" :key="sku[0]">{{ sku }}</span>
            </div>
          </div>
          <div class="qty">{{ t('checkout.qty') + ':' + item.quantity }}</div>
          <div class="price">{{t('global.currency')}}{{ item.sellPrice }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderItems',

  setup() {
    const { state } = useStore();
    const cartLists = computed(() => state.cartStore.cartItems);
    const { t } = useI18n();

    return {
      cartLists,
      t,
    };
  },
});
</script>
<style lang='scss' scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

.order-items {
  @include card-border();
  .title {
   @include card-title();
  }
  .order-body {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 38px;
      .img {
        margin-right: 37px;
        width: 90px;
        overflow: hidden;
        text-align: center;
        img {
          max-width: 100%;
        }
      }
      .text {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        .description,
        .qty {
          font-weight: 500;
          color: #545454;
          font-size: 14px;
        }
        .qty {
          width: 100px;
          text-align: center;
        }
        .price {
          flex: 1;
          text-align: right;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
        .description {
          width: 300px;
          display: flex;
          flex-direction: column;
          .name {
            font-size: 14px;
            font-weight: 500;
            color: $font-color-weak;
            line-height: 17px;
            // margin-bottom: 14px;
          }
          .detail {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            color: $font-color-weak;
            line-height: 15px;
            // margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            span {
              display: flex;
              margin-right: 10px;
              line-height: 18px;
              &:not(:last-child)::after {
                content: ",";
                display: block;
              }
            }
          }
        }
      }
    }
    .hr {
      border-bottom: 1px solid #dedede;
    }
  }
}
</style>
