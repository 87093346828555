
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GlobalSignSeal',
  mounted() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//seal.globalsign.com/SiteSeal/gmogs_image_90-35_en_dblue.js';
    this.$el.appendChild(script);
  },
});
