type Name = {
  name: string;
  Cname: string;
}
interface World {
  country: string;
  Ccountry: string;
  abbreviation: string;
  state: Name[];
}

const world: World[] = [
  {
    country: 'Spain',
    Ccountry: '西班牙',
    abbreviation: 'ES',
    state: [
      { name: 'Malaga', Cname: '马拉加' },
      { name: 'Alava', Cname: '阿拉瓦' },
      { name: 'Albacete', Cname: '阿尔瓦塞特' },
      { name: 'Alicante', Cname: '阿利坎特' },
      { name: 'La Rioja', Cname: '拉里奥哈' },
      { name: 'Asturias', Cname: '阿斯图利亚斯' },
      { name: 'Avila', Cname: '阿维拉' },
      { name: 'Barcelona', Cname: '巴塞罗那' },
      { name: 'Badajoz', Cname: '巴达霍斯' },
      { name: 'Baleares', Cname: '巴利阿里' },
      { name: 'Burgos', Cname: '布尔戈斯' },
      { name: 'Cadiz', Cname: '加的斯' },
      { name: 'Castellon', Cname: '卡斯特利翁' },
      { name: 'Caceres', Cname: '卡塞雷斯' },
      { name: 'Cludad Real', Cname: '卡斯蒂利亚' },
      { name: 'Cuenca', Cname: '昆卡' },
      { name: 'Gerona', Cname: '赫罗纳' },
      { name: 'Granada', Cname: '格拉纳达' },
      { name: 'Guadalajara', Cname: '瓜达拉哈拉' },
      { name: 'Guipuzcoa', Cname: '吉普斯夸' },
      { name: 'Huesca', Cname: '韦斯卡' },
      { name: 'Huelva', Cname: '韦尔瓦' },
      { name: 'Jaen', Cname: '哈恩' },
      { name: 'La Coruna', Cname: '拉科鲁尼亚' },
      { name: 'Almeria', Cname: '阿尔梅里亚' },
      { name: 'Leon', Cname: '莱昂' },
      { name: 'Provincia de Lugo', Cname: '卢戈' },
      { name: 'Lleida', Cname: '莱里达' },
      { name: 'Las Palmas', Cname: '拉斯帕尔马斯' },
      { name: 'Madrid', Cname: '马德里' },
      { name: 'Murcia', Cname: '穆尔西亚' },
      { name: 'Navarra', Cname: '纳瓦拉' },
      { name: 'Cordoba', Cname: '科尔多瓦' },
      { name: 'Orense', Cname: '奥伦塞' },
      { name: 'Palencia', Cname: '帕伦西亚' },
      { name: 'Provincia de Pontevedra', Cname: '蓬特韦德拉' },
      { name: 'Santa Cruz de Tenerife', Cname: '圣克鲁斯-德特内里费' },
      { name: 'Santander', Cname: '桑坦德' },
      { name: 'Segovia', Cname: '塞哥维亚' },
      { name: 'Salamanca', Cname: '萨拉曼卡' },
      { name: 'Soria', Cname: '索里亚' },
      { name: 'Sevilla', Cname: '塞维利亚' },
      { name: 'Tarragona', Cname: '塔拉戈纳' },
      { name: 'Teruel', Cname: '特鲁埃尔' },
      { name: 'Toledo', Cname: '托莱多' },
      { name: 'Valencia', Cname: '巴伦西亚' },
      { name: 'Valladolid', Cname: '巴利亚多利德' },
      { name: 'Vizcaya', Cname: '比斯开' },
      { name: 'Zaragoza', Cname: '萨拉戈萨' },
      { name: 'Zamora', Cname: '萨莫拉' },
    ],
  },
  {
    country: 'Italy',
    Ccountry: '意大利',
    abbreviation: 'IT',
    state: [
      {
        name: 'Alessandria',
        Cname: '亚历山德里亚',
      },
      {
        name: 'Ancona',
        Cname: '安科纳',
      },
      {
        name: 'Aosta',
        Cname: '奥斯塔',
      },
      {
        name: 'Ascoli Piceno',
        Cname: '阿斯科利皮切诺',
      },
      {
        name: 'Asti',
        Cname: '阿斯蒂',
      },
      {
        name: 'Brindisi',
        Cname: '布林迪西',
      },
      {
        name: 'Benevento',
        Cname: '贝内文托',
      },
      {
        name: 'Bergamo',
        Cname: '贝加莫',
      },
      {
        name: 'Biella',
        Cname: '布拉',
      },
      {
        name: 'Bologna',
        Cname: '博洛尼亚',
      },
      {
        name: 'Brescia',
        Cname: '布雷西亚',
      },
      {
        name: 'Barrie',
        Cname: '巴里',
      },
      {
        name: 'Cagliari',
        Cname: '卡利亚里',
      },
      {
        name: 'Genova',
        Cname: '热那亚',
      },
      {
        name: 'Como',
        Cname: '科摩',
      },
      {
        name: 'Campobasso',
        Cname: '坎波巴索',
      },
      {
        name: 'Crotone',
        Cname: '克罗托内',
      },
      {
        name: 'Caserta',
        Cname: '卡塞塔',
      },
      {
        name: 'Catania',
        Cname: '卡塔尼亚',
      },
      {
        name: 'Cuneo',
        Cname: '库内奥',
      },
      {
        name: 'Firenze',
        Cname: '佛罗伦萨',
      },
      {
        name: 'Foggia',
        Cname: '福贾',
      },
      {
        name: 'Ferrara',
        Cname: '费拉拉',
      },
      {
        name: 'Isernia',
        Cname: '伊塞尔尼亚',
      },
      {
        name: "L'Aquila",
        Cname: '拉奎拉',
      },
      {
        name: 'Lecce',
        Cname: '莱切',
      },
      {
        name: 'Lecco',
        Cname: '莱科',
      },
      {
        name: 'Livorno',
        Cname: '里窝那',
      },
      {
        name: 'Massa-Carrara',
        Cname: '马萨',
      },
      {
        name: 'Milano',
        Cname: '米兰',
      },
      {
        name: 'Modena',
        Cname: '摩德纳',
      },
      {
        name: 'Messina',
        Cname: '墨西拿',
      },
      {
        name: 'Matera',
        Cname: '马泰拉',
      },
      {
        name: 'Monza e Brianza',
        Cname: '蒙扎',
      },
      {
        name: 'Naples',
        Cname: '那不勒斯',
      },
      {
        name: 'Novara',
        Cname: '诺瓦拉',
      },
      {
        name: 'Olbia-Tempio',
        Cname: '奥尔比亚',
      },
      {
        name: 'Pavia',
        Cname: '帕维亚',
      },
      {
        name: 'Perugia',
        Cname: '佩鲁贾',
      },
      {
        name: 'Parma',
        Cname: '帕尔马',
      },
      {
        name: 'Palermo',
        Cname: '巴勒莫',
      },
      {
        name: 'Pordenone',
        Cname: '波代诺内',
      },
      {
        name: 'Pisa',
        Cname: '比萨',
      },
      {
        name: 'Cosenza',
        Cname: '科森扎',
      },
      {
        name: 'Catanzaro',
        Cname: '卡坦扎罗',
      },
      {
        name: 'Nuoro',
        Cname: '努奥罗',
      },
      {
        name: 'Oristano',
        Cname: '奥里斯塔诺',
      },
      {
        name: 'Potenza',
        Cname: '波坦察',
      },
      {
        name: 'Sassari',
        Cname: '萨萨里',
      },
      {
        name: 'Reggio Calabria',
        Cname: '雷焦卡拉布里亚',
      },
      {
        name: 'Reggio Emilia',
        Cname: '雷焦艾米利亚',
      },
      {
        name: 'Roma',
        Cname: '罗马',
      },
      {
        name: 'Salerno',
        Cname: '萨莱诺',
      },
      {
        name: 'Siena',
        Cname: '锡耶纳',
      },
      {
        name: 'La Spezia',
        Cname: '拉斯佩齐亚',
      },
      {
        name: 'Savona',
        Cname: '萨沃纳',
      },
      {
        name: 'Syracuse',
        Cname: '锡拉库扎',
      },
      {
        name: 'Taranto',
        Cname: '塔兰托',
      },
      {
        name: 'Trapani',
        Cname: '特拉帕尼',
      },
      {
        name: 'Turin',
        Cname: '都灵',
      },
      {
        name: 'Trieste',
        Cname: '的里雅斯特',
      },
      {
        name: 'Trento',
        Cname: '特伦托',
      },
      {
        name: 'Udine',
        Cname: '乌迪内',
      },
      {
        name: 'Venice',
        Cname: '威尼斯',
      },
      {
        name: 'Viterbo',
        Cname: '维泰博',
      },
      {
        name: 'Vercelli',
        Cname: '韦尔切利',
      },
    ],
  },
  {
    country: 'France',
    Ccountry: '法国',
    abbreviation: 'FR',
    state: [
      {
        name: 'Ajaccio',
        Cname: '阿雅克修',
      },
      {
        name: 'Amiens',
        Cname: '亚眠',
      },
      {
        name: 'Arles',
        Cname: '阿尔勒',
      },
      {
        name: 'Besancon',
        Cname: '贝桑松',
      },
      {
        name: 'Caen',
        Cname: '卡昂',
      },
      {
        name: 'Chalons-en-Champagne',
        Cname: '沙隆',
      },
      {
        name: 'Dijon',
        Cname: '第戎',
      },
      {
        name: 'Frejus',
        Cname: '弗雷瑞斯',
      },
      {
        name: 'Limoges',
        Cname: '利摩日',
      },
      {
        name: 'Lyon',
        Cname: '里昂',
      },
      {
        name: 'Lille',
        Cname: '里尔',
      },
      {
        name: 'Montpellier',
        Cname: '蒙彼利埃',
      },
      {
        name: 'Marseille',
        Cname: '马赛',
      },
      {
        name: 'Metz',
        Cname: '梅斯',
      },
      {
        name: 'Nice',
        Cname: '尼斯',
      },
      {
        name: 'Nantes',
        Cname: '南特',
      },
      {
        name: 'Orleans',
        Cname: '奥尔良',
      },
      {
        name: 'Paris',
        Cname: '巴黎',
      },
      {
        name: 'Aix-en-Provence',
        Cname: '艾克斯',
      },
      {
        name: 'Rennes',
        Cname: '雷恩',
      },
      {
        name: 'Toulouse',
        Cname: '图卢兹',
      },
      {
        name: 'Rouen',
        Cname: '鲁昂',
      },
      {
        name: 'Valence',
        Cname: '瓦朗斯',
      },
    ],
  },
  {
    country: 'Germany',
    Ccountry: '德国',
    abbreviation: 'DE',
    state: [
      {
        name: 'Berlin',
        Cname: '柏林',
      },
      {
        name: 'Bremen',
        Cname: '不来梅',
      },
      {
        name: 'Hamburg',
        Cname: '汉堡',
      },
      {
        name: 'Augsburg',
        Cname: '奥格斯堡',
      },
      {
        name: 'Ansbach',
        Cname: '安斯巴格',
      },
      {
        name: 'Arnsberg',
        Cname: '阿恩斯贝格',
      },
      {
        name: 'Bielefeld',
        Cname: '比勒费尔德',
      },
      {
        name: 'Bochum',
        Cname: '波鸿',
      },
      {
        name: 'Brunswick',
        Cname: '不伦瑞克',
      },
      {
        name: 'Bayreuth',
        Cname: '拜伊罗特',
      },
      {
        name: 'Cologne',
        Cname: '科隆',
      },
      {
        name: 'Chemnitz',
        Cname: '开姆尼斯',
      },
      {
        name: 'Darmstadt',
        Cname: '达姆施塔特',
      },
      {
        name: 'Dessau',
        Cname: '德绍',
      },
      {
        name: 'Detmold',
        Cname: '代特莫尔特',
      },
      {
        name: 'Dresden',
        Cname: '德累斯顿',
      },
      {
        name: 'Dusseldorf',
        Cname: '杜塞尔多夫',
      },
      {
        name: 'Erfurt',
        Cname: '爱尔福特',
      },
      {
        name: 'Freiburg',
        Cname: '弗赖堡',
      },
      {
        name: 'Frankfurt',
        Cname: '法兰克福',
      },
      {
        name: 'GieBen',
        Cname: '吉森',
      },
      {
        name: 'Halle',
        Cname: '哈雷',
      },
      {
        name: 'Hannover',
        Cname: '汉诺威',
      },
      {
        name: 'Karlsruhe',
        Cname: '卡尔斯鲁厄',
      },
      {
        name: 'Kassel',
        Cname: '卡塞尔',
      },
      {
        name: 'Kiel',
        Cname: '基尔',
      },
      {
        name: 'Koblenz',
        Cname: '科布伦次',
      },
      {
        name: 'Luneburg',
        Cname: '吕讷堡',
      },
      {
        name: 'Landshut',
        Cname: '兰茨胡特',
      },
      {
        name: 'Leipzig',
        Cname: '莱比锡',
      },
      {
        name: 'Magdeburg',
        Cname: '马格德堡',
      },
      {
        name: 'Mainz',
        Cname: '美因兹',
      },
      {
        name: 'Mannheim',
        Cname: '曼海姆',
      },
      {
        name: 'Munich',
        Cname: '慕尼黑',
      },
      {
        name: 'Muenster',
        Cname: '明斯特',
      },
      {
        name: 'Nuremberg',
        Cname: '纽伦堡',
      },
      {
        name: 'Potsdam',
        Cname: '波茨坦',
      },
      {
        name: 'Stuttgart',
        Cname: '斯图加特',
      },
      {
        name: 'Schwerin',
        Cname: '什未林',
      },
      {
        name: 'Trier',
        Cname: '特里尔',
      },
      {
        name: 'Wiesbaden',
        Cname: '威斯巴登',
      },
      {
        name: 'Wuerzburg',
        Cname: '维尔茨堡',
      },
    ],
  },
  {
    country: 'United States',
    Ccountry: '美国',
    abbreviation: 'US',
    state: [
      {
        name: 'Alaska',
        Cname: '阿拉斯加',
      },
      {
        name: 'Alabama',
        Cname: '阿拉巴马',
      },
      {
        name: 'Arkansas',
        Cname: '阿肯色',
      },
      {
        name: 'Arizona',
        Cname: '亚利桑那',
      },
      {
        name: 'California',
        Cname: '加利福尼亚',
      },
      {
        name: 'Colorado',
        Cname: '科罗拉多',
      },
      {
        name: 'Connecticut',
        Cname: '康涅狄格',
      },
      {
        name: 'District of Columbia',
        Cname: '哥伦比亚特区',
      },
      {
        name: 'Delaware',
        Cname: '特拉华',
      },
      {
        name: 'Florida',
        Cname: '佛罗里达',
      },
      {
        name: 'Georgia',
        Cname: '佐治亚',
      },
      {
        name: 'Hawaii',
        Cname: '夏威夷',
      },
      {
        name: 'Iowa',
        Cname: '爱荷华',
      },
      {
        name: 'Idaho',
        Cname: '爱达荷',
      },
      {
        name: 'Illinois',
        Cname: '伊利诺斯',
      },
      {
        name: 'Indiana',
        Cname: '印第安那',
      },
      {
        name: 'Kansas',
        Cname: '堪萨斯',
      },
      {
        name: 'Kentucky',
        Cname: '肯塔基',
      },
      {
        name: 'Louisiana',
        Cname: '路易斯安那',
      },
      {
        name: 'Massachusetts',
        Cname: '马萨诸塞',
      },
      {
        name: 'Maryland',
        Cname: '马里兰',
      },
      {
        name: 'Maine',
        Cname: '缅因',
      },
      {
        name: 'Michigan',
        Cname: '密歇根',
      },
      {
        name: 'Minnesota',
        Cname: '明尼苏达',
      },
      {
        name: 'Missouri',
        Cname: '密苏里',
      },
      {
        name: 'Mississippi',
        Cname: '密西西比',
      },
      {
        name: 'Montana',
        Cname: '蒙大拿',
      },
      {
        name: 'North Carolina',
        Cname: '北卡罗来纳',
      },
      {
        name: 'North Dakota',
        Cname: '北达科他',
      },
      {
        name: 'Nebraska',
        Cname: '内布拉斯加',
      },
      {
        name: 'New Hampshire',
        Cname: '新罕布什尔',
      },
      {
        name: 'New Jersey',
        Cname: '新泽西',
      },
      {
        name: 'New Mexico',
        Cname: '新墨西哥',
      },
      {
        name: 'Nevada',
        Cname: '内华达',
      },
      {
        name: 'New York',
        Cname: '纽约',
      },
      {
        name: 'Ohio',
        Cname: '俄亥俄',
      },
      {
        name: 'Oklahoma',
        Cname: '俄克拉荷马',
      },
      {
        name: 'Oregon',
        Cname: '俄勒冈',
      },
      {
        name: 'Pennsylvania',
        Cname: '宾夕法尼亚',
      },
      {
        name: 'Rhode Island',
        Cname: '罗德岛',
      },
      {
        name: 'South Carolina',
        Cname: '南卡罗来纳',
      },
      {
        name: 'South Dakota',
        Cname: '南达科他',
      },
      {
        name: 'Tennessee',
        Cname: '田纳西',
      },
      {
        name: 'Texas',
        Cname: '德克萨斯',
      },
      {
        name: 'Utah',
        Cname: '犹他',
      },
      {
        name: 'Virginia',
        Cname: '维吉尼亚',
      },
      {
        name: 'Vermont',
        Cname: '佛蒙特',
      },
      {
        name: 'Washington',
        Cname: '华盛顿',
      },
      {
        name: 'Wisconsin',
        Cname: '威斯康星',
      },
      {
        name: 'West Virginia',
        Cname: '西佛吉尼亚',
      },
      {
        name: 'Wyoming',
        Cname: '怀俄明',
      },
    ],
  },
  {
    country: 'United Kingdom',
    Ccountry: '英国',
    abbreviation: 'GB',
    state: [
      {
        name: 'Abbotsford',
        Cname: '阿伯茨福',
      },
      {
        name: 'Barrie',
        Cname: '巴里',
      },
      {
        name: 'Brampton',
        Cname: '基奇纳',
      },
      {
        name: 'Calgary',
        Cname: '卡里加里',
      },
      {
        name: 'Cape Breton',
        Cname: '布列塔尼角',
      },
      {
        name: 'Charlottetown',
        Cname: '夏洛特敦',
      },
      {
        name: 'Edmonton',
        Cname: '埃德蒙顿',
      },
      {
        name: 'Fredericton',
        Cname: '弗雷德里顿',
      },
      {
        name: 'Guelph',
        Cname: '圭尔夫',
      },
      {
        name: 'Halifax',
        Cname: '哈利法克斯',
      },
      {
        name: 'Hamilton',
        Cname: '哈密尔顿',
      },
      {
        name: 'Iqaluit',
        Cname: '伊魁特',
      },
      {
        name: 'Kingston',
        Cname: '金斯敦',
      },
      {
        name: 'Kelowna',
        Cname: '基劳纳',
      },
      {
        name: 'London',
        Cname: '伦敦',
      },
      {
        name: 'Montreal',
        Cname: '蒙特利尔',
      },
      {
        name: 'Oshawa',
        Cname: '奥沙瓦',
      },
      {
        name: 'Ottawa',
        Cname: '渥太华',
      },
      {
        name: 'Quebec',
        Cname: '魁北克',
      },
      {
        name: 'Regina',
        Cname: '里贾纳',
      },
      {
        name: 'Saskatoon',
        Cname: '萨斯卡通',
      },
      {
        name: 'Sherbrooke',
        Cname: '舍布鲁克',
      },
      {
        name: 'Santa Catarina',
        Cname: '圣卡塔琳娜',
      },
      {
        name: "Saint-John's",
        Cname: '圣约翰斯',
      },
      {
        name: 'Sudbury',
        Cname: '萨德伯里',
      },
      {
        name: 'Thunder Bay',
        Cname: '桑德贝',
      },
      {
        name: 'Toronto',
        Cname: '多伦多',
      },
      {
        name: 'Trois-Rivieres',
        Cname: '三河城',
      },
      {
        name: 'Vancouver',
        Cname: '温哥华',
      },
      {
        name: 'Victoria',
        Cname: '维多利亚',
      },
      {
        name: 'Windsor',
        Cname: '温莎',
      },
      {
        name: 'Winnipeg',
        Cname: '温尼伯',
      },
      {
        name: 'Whitehorse',
        Cname: '怀特霍斯',
      },
      {
        name: 'Yellowknife',
        Cname: '耶洛奈夫',
      },
    ],
  },
  {
    country: 'Netherlands',
    Ccountry: '荷兰',
    abbreviation: 'NL',
    state: [
      {
        name: 'Almere',
        Cname: '阿尔梅勒',
      },
      {
        name: 'Amersfoort',
        Cname: '阿默斯福特',
      },
      {
        name: 'Amsterdam',
        Cname: '阿姆斯特丹',
      },
      {
        name: 'Apeldoorn',
        Cname: '阿珀尔多伦',
      },
      {
        name: 'Arnhem',
        Cname: '阿纳姆',
      },
      {
        name: 'Assen',
        Cname: '阿森',
      },
      {
        name: 'Breda',
        Cname: '布雷达',
      },
      {
        name: 'Dordrecht',
        Cname: '多德雷赫特',
      },
      {
        name: 'Ede',
        Cname: '埃德',
      },
      {
        name: 'Eindhoven',
        Cname: '埃因霍芬',
      },
      {
        name: 'Emmen',
        Cname: '埃门',
      },
      {
        name: 'Enschede',
        Cname: '恩斯赫德',
      },
      {
        name: 'Groningen',
        Cname: '格罗宁根',
      },
      {
        name: 'Hague',
        Cname: '海牙',
      },
      {
        name: 'Hoofddorp',
        Cname: '霍夫多尔普',
      },
      {
        name: 'Haarlem',
        Cname: '哈勒姆',
      },
      {
        name: "'s-Hertogenbosch",
        Cname: '斯海尔托亨博思',
      },
      {
        name: 'Lelystad',
        Cname: '莱利斯塔德',
      },
      {
        name: 'Leiden',
        Cname: '莱顿',
      },
      {
        name: 'Leeuwarden',
        Cname: '吕伐登',
      },
      {
        name: 'Middelburg',
        Cname: '米德尔堡',
      },
      {
        name: 'Maastricht',
        Cname: '马斯特里赫特',
      },
      {
        name: 'Nijmegen',
        Cname: '奈梅亨',
      },
      {
        name: 'Rotterdam',
        Cname: '鹿特丹',
      },
      {
        name: 'Tilburg',
        Cname: '蒂尔堡',
      },
      {
        name: 'Utrecht',
        Cname: '乌得勒支',
      },
      {
        name: 'Zoetermeer',
        Cname: '佐特尔梅',
      },
      {
        name: 'Zwolle',
        Cname: '兹沃勒',
      },
    ],
  },
  {
    country: 'Ireland',
    Ccountry: '爱尔兰',
    abbreviation: 'IE',
    state: [
      {
        name: 'Cork',
        Cname: '科克',
      },
      {
        name: 'Clare',
        Cname: '克莱尔',
      },
      {
        name: 'Cavan',
        Cname: '卡范',
      },
      {
        name: 'Carlow',
        Cname: '卡洛',
      },
      {
        name: 'Dublin',
        Cname: '都柏林',
      },
      {
        name: 'Donegal',
        Cname: '多内加尔',
      },
      {
        name: 'Galway',
        Cname: '戈尔韦',
      },
      {
        name: 'Kildare',
        Cname: '基尔代尔',
      },
      {
        name: 'Kilkenny',
        Cname: '基尔肯尼',
      },
      {
        name: 'Kerry',
        Cname: '凯里',
      },
      {
        name: 'Laois',
        Cname: '崂斯',
      },
      {
        name: 'Longford',
        Cname: '朗福德',
      },
      {
        name: 'Limerick',
        Cname: '利默里克',
      },
      {
        name: 'Leitrim',
        Cname: '利特里姆',
      },
      {
        name: 'Louth',
        Cname: '劳斯',
      },
      {
        name: 'Monaghan',
        Cname: '莫内根',
      },
      {
        name: 'Meath',
        Cname: '米斯',
      },
      {
        name: 'Mayo',
        Cname: '梅奥',
      },
      {
        name: 'Offaly',
        Cname: '奥法利',
      },
      {
        name: 'Roscommon',
        Cname: '罗斯康芒',
      },
      {
        name: 'Sligo',
        Cname: '斯莱戈',
      },
      {
        name: 'Tipperary',
        Cname: '蒂珀雷里',
      },
      {
        name: 'Waterford',
        Cname: '沃特福德',
      },
      {
        name: 'Wicklow',
        Cname: '威克洛',
      },
      {
        name: 'Westmeath',
        Cname: '西米斯',
      },
      {
        name: 'Wexford',
        Cname: '韦克斯福德',
      },
    ],
  },
];

const worldOptions = world.map((item) => ({
  label: item.country,
  value: item.abbreviation,
  // state: item.state.map((arr) => ({
  //   label: arr.name,
  //   value: arr.name,
  // })),
  state: item.state,
}));

export default worldOptions;
