
import importComponents from '@/utils/import-components';
import { defineComponent, computed, onBeforeMount } from 'vue';
import { Divider, Anchor, Button } from 'ant-design-vue';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import { cartItem } from '@/services/cart';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import GlobalSignSeal from '@/components/common/GlobalSignSeal.vue';

export default defineComponent({
  name: 'Summary',
  components: {
    GlobalSignSeal,
    ...importComponents(Anchor, Divider, Button),
  },
  props: {
    loading: Boolean,
    disabled: {
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const { state } = useStore();

    const { t } = useI18n();

    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        const res = await cartItem();
        // eslint-disable-next-line no-unused-expressions
        res.code === 51101 ? router.push('/404') : null;
      }
    });

    const cartPrice = computed(() => state.cartStore.cartPrice);

    const onSubmit = () => ctx.emit('submit');

    return {
      cartPrice,
      onSubmit,
      t,
    };
  },
});
