
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderItems',

  setup() {
    const { state } = useStore();
    const cartLists = computed(() => state.cartStore.cartItems);
    const { t } = useI18n();

    return {
      cartLists,
      t,
    };
  },
});
