
import importComponents from '@/utils/import-components';
import { useForm } from '@ant-design-vue/use';
import {
  Divider,
  Form,
  Input,
  Select,
  Button,
  Radio,
  Popover,
  Collapse,
  DatePicker,
  Checkbox,
  message,
} from 'ant-design-vue';
import {
  defineComponent,
  reactive,
  ref,
  computed,
  onBeforeMount,
  onMounted,
} from 'vue';
import worldOptions from '@/utils/world-area';
import {
  addAddress,
  changeAddress,
  getAddress,
  payToken,
} from '@/services/user';
import Cookies from 'js-cookie';
import type { Address } from '@/services/interface/response';
import { useRoute, useRouter } from 'vue-router';
import { Alert } from '@/components/Alert/index';
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import OrderItems from '@/components/checkout/OrderItems.vue';
import Summary from '@/components/checkout/Summary.vue';
import { useStore } from 'vuex';
import {
  cardPayCancel,
  cardPaySuccess,
  payOrder,
  VisitorCardPayCancel,
  visitorCardPaySuccess,
  VisitorPayOrder,
} from '@/services/pay';
import Load from '@/components/common/Load.vue';
import { ModalFuncProps } from 'ant-design-vue/lib/modal';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElementLocale,
  StripeElementChangeEvent,
} from '@stripe/stripe-js';

interface OrderItem {
  img: string;
  description: string;
  qty: number;
  price: number;
}

interface StateOption {
  label: string;
  value: string;
}

type PayMode = 0 | 1 | 2;

interface Card {
  cardNumber: string;
  expirationDate: moment.Moment;
  securityCode: string;
}

const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default defineComponent({
  name: 'Checkout',
  components: {
    OrderItems,
    Summary,
    Load,
    'exclamation-circleOutlined': ExclamationCircleOutlined,
    ...importComponents(
      Divider,
      Form,
      Form.Item,
      Input,
      Select,
      Button,
      Radio,
      Radio.Group,
      Popover,
      Collapse,
      Collapse.Panel,
      DatePicker.MonthPicker,
      Popover,
      Checkbox,
    ),
  },
  setup() {
    const { dispatch, state } = useStore();
    const loading = ref(true);
    const router = useRouter();
    const route = useRoute();
    const processing = ref(false);
    const placeholder = ref('');
    const phoneNumber = ref('');
    const { t, locale } = useI18n();

    // 支付方式 0：paypal 1：信用卡
    const paymentMode = ref<PayMode>(1);

    const disabled = ref(true);
    // 游客是否同意自动注册
    const isAgree = ref(false);

    // 添加地址表单数据
    const modelRef = reactive({
      firstName: '',
      lastName: '',
      countryOrRegion: '',
      address: '',
      city: '',
      stateOrProvince: '',
      zipCode: '',
      email: '',
      phoneNumber: '',
      addressId: '',
    });

    const paymentRef = reactive<Card>({
      cardNumber: '',
      expirationDate: moment(),
      securityCode: '',
    });

    const stateOptions = ref<StateOption[]>([]);
    // 州选项
    const caseState = (index: number) => worldOptions[index].state.map((arr) => ({
      label: arr.name,
      value: arr.name,
    }));

    // 当切换国家的时候 Zip Code和电话号码前缀(国际区号)变换
    const onChange = (value: string) => {
      switch (value) {
        case 'ES':
          phoneNumber.value = 'ES +34';
          stateOptions.value = caseState(0);
          break;
        case 'IT':
          phoneNumber.value = 'IT +39';
          stateOptions.value = caseState(1);
          break;
        case 'FR':
          phoneNumber.value = 'FR +33';
          stateOptions.value = caseState(2);
          break;
        case 'DE':
          phoneNumber.value = 'DE +49';
          stateOptions.value = caseState(3);
          break;
        case 'US':
          phoneNumber.value = 'US +1';
          stateOptions.value = caseState(4);
          break;
        case 'GB':
          phoneNumber.value = 'GB +44';
          stateOptions.value = caseState(5);
          break;
        case 'NL':
          phoneNumber.value = 'NL +31';
          stateOptions.value = caseState(6);
          break;
        case 'IE':
          phoneNumber.value = 'IE +353';
          stateOptions.value = caseState(7);
          break;
        default:
          phoneNumber.value = '';
      }
      modelRef.stateOrProvince = '';
      modelRef.address = '';
      modelRef.city = '';
    };

    // 表单校验提示
    const rulesRef = reactive({
      firstName: [
        {
          required: true,
          message: t('checkout.enterA') + t('user.firstName'),
        },
      ],
      lastName: [
        {
          required: true,
          message: t('checkout.enterA') + t('user.lastName'),
        },
      ],
      countryOrRegion: [
        {
          required: true,
          message: t('checkout.pleaseChoose') + t('checkout.country'),
        },
      ],
      address: [
        {
          required: true,
          message: t('checkout.enterAn') + t('checkout.address'),
        },
      ],
      city: [
        {
          required: true,
          message: t('checkout.enterA') + t('checkout.city'),
        },
      ],
      stateOrProvince: [
        {
          required: true,
          message: t('checkout.pleaseChoose') + t('checkout.state'),
        },
      ],
      zipCode: [
        {
          required: true,
          message: t('checkout.pleaseEnter') + t('checkout.zipCode'),
        },
      ],
      email: [
        {
          validator: (rule: ValidationRule, value: string) => {
            if (value === '') {
              return Promise.reject(new Error(t('contactUs.isRequired')));
            }
            if (!EMAIL_PATTERN.test(value)) {
              return Promise.reject(new Error(t('user.emailMessage')));
            }
            return Promise.resolve();
          },
          required: true,
          trigger: 'change',
        },
      ],
      phoneNumber: [
        {
          validator: (rule: ValidationRule, value: string) => {
            if (value === '') {
              return Promise.reject(new Error(t('checkout.phoneMessage')));
            }
            if (/[^\d]/g.test(value)) {
              const empty = /[^\d]/g;
              modelRef.phoneNumber = modelRef.phoneNumber.replace(empty, '');
            }
            return Promise.resolve();
          },
          required: true,
          trigger: 'change',
        },
      ],
    });

    const paymentRule = reactive({
      cardNumber: [
        {
          required: true,
          message:
            t('checkout.pleaseEnter')
            + t('checkout.correct')
            + t('checkout.cardNumber'),
        },
      ],
      expirationDate: [
        {
          required: true,
          message: t('checkout.pleaseSelect') + t('checkout.expirationDate'),
        },
      ],
      securityCode: [
        {
          required: true,
          message: t('checkout.pleaseEnter') + t('checkout.securityCode'),
        },
      ],
    });

    const { validate, validateInfos } = useForm(modelRef, rulesRef);

    const { validate: paymentValidate, validateInfos: paymentValidateInfos } = useForm(paymentRef, paymentRule);

    const creditCardImages = [
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/visa@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/masterCard@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/americanExpress@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/jCB@2x.png',
      // 'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/unionPay@2x.png',
    ];
    // 0: 老客户, 1: 新客户, 2: 选择地址, 3: 编辑地址。
    const checkOutStatus = ref(1);

    // 存放所有的地址数据的数组
    const user: Address[] = reactive([]);
    // 存放默认显示的地址
    const chooseUser = computed(
      () => user.filter((item) => item.isDefault === '1')[0],
    );

    let stripe: Stripe;
    let cardNumber: StripeCardNumberElement;
    let cardExpiry: StripeCardExpiryElement;
    let cardCvc: StripeCardCvcElement;

    const createCard = async () => {
      stripe = (await loadStripe(process.env.VUE_APP_PAY_KEY)) as Stripe;
      const elements = stripe.elements({
        locale: locale.value.split('_')[0] as StripeElementLocale,
      });
      cardNumber = elements.create('cardNumber', {
        showIcon: true,
        iconStyle: 'solid',
      });
      cardExpiry = elements.create('cardExpiry');
      cardCvc = elements.create('cardCvc', {
        placeholder: 'CVV',
      });

      setTimeout(() => {
        cardNumber.mount('#card-number');
        cardExpiry.mount('#card-expiry');
        cardCvc.mount('#card-cvc');
      }, 3000);

      const getError = (event: StripeElementChangeEvent) => {
        disabled.value = event.empty;
        if (event.error) {
          const modal = Alert.error(event.error.message);
          setTimeout(() => {
            modal.destroy();
          }, 2000);
        }
      };
      cardNumber.on('change', getError);
      cardExpiry.on('change', getError);
      cardCvc.on('change', getError);
    };

    createCard();

    onBeforeMount(async () => {
      // 已登录的话加载直接获取用户地址信息
      if (Cookies.get('token')) {
        // 游客选择转账支付登录成功之后要获取一下购物车才能提交
        if (route.query.f) {
          dispatch('getCartItems');
          if (Cookies.get('address')) {
            Object.assign(modelRef, JSON.parse(Cookies.get('address') as string));
          }
        }
        try {
          const res = await getAddress();
          user.push(...res.data);
          if (user.length > 0) {
            // 如果该用户添加过地址，则直接显示默认地址
            checkOutStatus.value = 0;
          }
          loading.value = false;
          if (route.params.errorCode) {
            let modal: {
              destroy: () => void;
              update: (newConfig: ModalFuncProps) => void;
            };
            switch (route.params.errorCode) {
              case '51055':
                modal = Alert.error(t('checkout.paymentFailed'));
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                break;
              default:
                modal = Alert.error(t('checkout.paymentFailed'));
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                break;
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else if (!localStorage.getItem('touristToken')) {
        // 解决游客购完物，到订单完成页面清掉游客token，再次购买checkout没有游客token会校验不通过
        localStorage.setItem(
          'touristToken',
          Date.parse(new Date().toString())
            + Math.random().toFixed(2).toString(),
        );
      }
      loading.value = false;
    });

    // 抽取success弹窗代码
    const alertFunc = (value: string) => {
      const modal = Alert.success(value);
      setTimeout(() => {
        modal.destroy();
        router.push('/checkout');
        checkOutStatus.value = 0;
      }, 2000);
    };

    const errorModal = (error: string) => {
      const modal = Alert.error(t(error));
      setTimeout(() => {
        modal.destroy();
      }, 2000);
      processing.value = false;
    };

    const payWithCard = async (
      clientSecret: string,
      orderNumber: string,
      userId?: number,
    ) => {
      stripe
        .confirmCardPayment(clientSecret, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          receipt_email: sessionStorage.getItem('userEmail') as string,
          // eslint-disable-next-line @typescript-eslint/camelcase
          payment_method: {
            card: cardNumber,
          },
        })
        .then(async (res) => {
          if (res.paymentIntent?.status) {
            let response;
            if (userId) {
              response = await visitorCardPaySuccess(orderNumber, userId);
            } else {
              response = await cardPaySuccess(orderNumber);
            }
            if (response.success) {
              window.location.replace(`/order-complete/${orderNumber}`);
              // 清空游客购物车
              dispatch('removeVisitorCart');
            } else {
              errorModal(response.message);
            }
          } else {
            let response;
            if (localStorage.getItem('touristToken')) {
              response = await VisitorCardPayCancel(orderNumber);
            } else {
              response = await cardPayCancel(orderNumber);
              if (response.success) {
                // 新用户添加地址已成功，信用卡支付失败，无需再一次添加地址
                if (checkOutStatus.value === 1) {
                  checkOutStatus.value = 0;
                  getAddress().then((re) => {
                    Object.assign(user, re.data);
                  });
                }
              }
            }
            processing.value = false;
            const dialogFun = (value: string) => {
              Alert.error(t(value), true);
            };
            // 支付失败，取消订单，给一些友好的提示，失败原因：https://stripe.com/docs/error-codes/card-declined，根据decline_code查找
            if (res.error?.type === 'api_connection_error') {
              dialogFun('shipping.netWorkError');
            }
            switch (res.error?.decline_code) {
              case 'insufficient_funds':
              case 'card_velocity_exceeded':
              case 'invalid_amount':
              case 'withdrawal_count_limit_exceeded':
                // 余额不足
                dialogFun('shipping.insufficient');
                break;
              case 'fraudulent':
              case 'merchant_blacklist':
              case 'invalid_account':
              case 'new_account_information_available':
                // 可能有欺诈行为
                dialogFun('shipping.workingError');
                break;
              case 'call_issuer':
              case 'do_not_honor':
              case 'do_not_try_again':
              case 'generic_decline':
              case 'no_action_taken':
              case 'revocation_of_all_authorizations':
              case 'revocation_of_authorization':
              case 'security_violation':
              case 'service_not_allowed':
              case 'stop_payment_order':
              case 'transaction_not_allowed':
              case 'try_again_later':
              case 'reenter_transaction':
              case 'not_permitted':
                // 由于未知原因,该卡已被拒绝,应联系发卡人以获取更多信息
                dialogFun('shipping.unknownReason');
                break;
              case 'expired_card':
                // 卡过期
                dialogFun('shipping.expired');
                break;
              case 'incorrect_number':
              case 'invalid_number':
                // 卡号不正确
                dialogFun('shipping.cardNumber');
                break;
              case 'pin_try_exceeded':
                // 已超出允许的 PIN 尝试次数
                dialogFun('shipping.pinTries');
                break;
              case 'incorrect_pin':
              case 'invalid_pin':
                // PIN 不正确
                dialogFun('shipping.pinError');
                break;
              case 'offline_pin_required':
              case 'online_or_offline_pin_required':
                // 需要输入PIN进行支付
                dialogFun('shipping.pinEnter');
                break;
              case 'invalid_cvc':
              case 'incorrect_cvc':
                // CVV不正确
                dialogFun('shipping.cvc');
                break;
              case 'lost_card':
              case 'pickup_card':
              case 'restricted_card':
              case 'stolen_card':
                // 可能报告丢失或被盗
                dialogFun('shipping.workingError');
                break;
              case 'processing_error':
                // 处理卡时发生错误，尝试重试或者稍后
                dialogFun('shipping.processingError');
                break;
              case 'card_not_supported':
                // 该卡不支持此类购买
                dialogFun('shipping.notSupport');
                break;
              case 'approve_with_id':
              case 'issuer_not_available':
                // 付款无法授权，应再次尝试付款，如果仍无法处理，客户需要联系其发卡人。
                dialogFun('shipping.authorized');
                break;
              case 'invalid_expiry_month':
                // 到期月无效。
                dialogFun('shipping.month');
                break;
              case 'invalid_expiry_year':
                // 到期年无效。
                dialogFun('shipping.year');
                break;
              case 'incorrect_zip':
                // 邮政编码/邮政编码不正确。
                dialogFun('shipping.codeNum');
                break;
              case 'currency_not_supported':
                // 该卡不支持指定的货币。
                dialogFun('shipping.currency');
                break;
              case 'authentication_required':
                // 该卡验证失败。
                dialogFun('shipping.verification');
                break;
              case 'duplicate_transaction':
                // 最近提交相同交易。
                dialogFun('shipping.submitted');
                break;
              case 'testmode_decline':
                // 使用了条纹测试卡号。
                dialogFun('shipping.genuine');
                break;
              default:
                // 其他
                dialogFun(res.error?.message as string);
                break;
            }
          }
        });
    };

    // 提取支付token和支付订单接口
    const pay = async (addressId?: string) => {
      try {
        const res = await payToken();
        processing.value = true;
        let response;
        if (addressId) {
          const data = {
            addressId,
            ids: state.cartStore.cartIdArray,
            signature: res.data,
            payMode: paymentMode.value,
            via: 'pc',
          };
          response = await payOrder(data);
        } else {
          Cookies.set('address', modelRef);
          const data = {
            ...modelRef,
            signature: res.data,
            cartDTOList: state.ShoppingCart,
            isRegistration: isAgree.value,
            payMode: paymentMode.value,
            touristToken: localStorage.getItem('touristToken') as string,
            via: 'pc',
          };
          response = await VisitorPayOrder(data);
        }
        if (response.success && paymentMode.value === 0) { // PayPal
          window.location.href = response.data.payUrl as string;
          if (localStorage.getItem('touristToken')) {
            localStorage.removeItem('touristToken');
          }
        } else if (response.success && paymentMode.value === 1) { // 卡
          if (localStorage.getItem('touristToken')) {
            payWithCard(
              response.data.clientSecret as string,
              response.data.orderNumber as string,
              response.data.userId,
            );
          } else {
            payWithCard(
              response.data.clientSecret as string,
              response.data.orderNumber as string,
            );
          }
        } else if (response.success && paymentMode.value === 2) { // 转账
          if (Cookies.get('token')) {
            dispatch('cleanCart');
            dispatch('removeVisitorCart');
            router.push(`/user/orders-details?${response.data.orderNumber}`);
          } else {
            // 游客想转账支付，让他去注册登录
            router.push('/login?form=payment');
          }
        } else if (response.code === 21018) {
          errorModal('checkout.inventoryShortage');
        } else if (response.code === 21010) {
          errorModal('checkout.emailRegistered');
          isAgree.value = false;
        } else if (response.code === 33000) {
          errorModal(response.message);
          processing.value = false;
        } else {
          // errorModal('checkout.addressMessage');
          errorModal(response.message);
          processing.value = false;
        }
      } catch (e) {
        processing.value = false;
      }
    };
    // const pay = (addressId?: string) => payToken()
    //   .then(async (res) => {
    //     processing.value = true;
    //     let response;
    //     if (addressId) {
    //       // 游客选择转账支付登录成功之后要获取一下购物车才能提交
    //       if (route.query.f) {
    //         dispatch('getCartItems')
    //           .then(() => {
    //             // console.log(state.cartIdArray);
    //           });
    //       }
    //       const data = {
    //         addressId,
    //         ids: state.cartStore.cartIdArray,
    //         signature: res.data,
    //         payMode: paymentMode.value,
    //         via: 'pc',
    //       };
    //       response = await payOrder(data);
    //     } else {
    //       const data = {
    //         ...modelRef,
    //         signature: res.data,
    //         cartDTOList: state.ShoppingCart,
    //         isRegistration: isAgree.value,
    //         payMode: paymentMode.value,
    //         touristToken: localStorage.getItem('touristToken') as string,
    //         via: 'pc',
    //       };
    //       response = await VisitorPayOrder(data);
    //     }
    //     if (response.success && paymentMode.value === 0) { // PayPal
    //       window.location.href = response.data.payUrl as string;
    //       if (localStorage.getItem('touristToken')) {
    //         localStorage.removeItem('touristToken');
    //       }
    //     } else if (response.success && paymentMode.value === 1) { // 卡
    //       if (localStorage.getItem('touristToken')) {
    //         payWithCard(
    //           response.data.clientSecret as string,
    //           response.data.orderNumber as string,
    //           response.data.userId,
    //         );
    //       } else {
    //         payWithCard(
    //           response.data.clientSecret as string,
    //           response.data.orderNumber as string,
    //         );
    //       }
    //     } else if (response.success && paymentMode.value === 2) { // 转账
    //       if (Cookies.get('token')) {
    //         dispatch('cleanCart');
    //         dispatch('removeVisitorCart');
    //         router.push(`/user/orders-details?${response.data.orderNumber}`);
    //       } else {
    //         // 游客想转账支付，让他去注册登录
    //         router.push('/login?form=payment');
    //       }
    //     } else if (response.code === 21018) {
    //       errorModal('checkout.inventoryShortage');
    //     } else if (response.code === 21010) {
    //       errorModal('checkout.emailRegistered');
    //       isAgree.value = false;
    //     } else {
    //       errorModal('checkout.addressMessage');
    //     }
    //   })
    //   .catch(() => {
    //     processing.value = false;
    //   });

    // 表单提交按钮 & 该用户第一次添加地址时此按钮不出现直接去支付
    const onSubmit = () => {
      if (checkOutStatus.value === 1 || checkOutStatus.value === 3) {
        validate().then(async () => {
          processing.value = true;
          if (checkOutStatus.value === 1 && Cookies.get('token')) {
            // 新增 调新增地址接口
            try {
              const res = await addAddress(modelRef);
              if (res.success && user.length !== 0) {
                const { data } = await getAddress();
                Object.assign(user, data);
                alertFunc(t('checkout.addSuccessfully'));
                processing.value = false;
              } else if (!res.success) {
                const modal = Alert.error(res.message);
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                processing.value = false;
              } else {
                pay(res.data).catch(() => {
                  processing.value = false;
                });
              }
            } catch (err) {
              return err;
            }
          } else if (checkOutStatus.value === 1 && !Cookies.get('token')) {
            pay();
          } else if (checkOutStatus.value === 3) {
            // 编辑 调更新修改接口
            try {
              const res = await changeAddress(modelRef, modelRef.addressId);
              if (res.success) {
                const { data } = await getAddress();
                Object.assign(user, data);
                alertFunc(t('checkout.updateSuccessfully'));
                processing.value = false;
              } else {
                const modal = Alert.error(res.message);
                setTimeout(() => {
                  modal.destroy();
                }, 2000);
                processing.value = false;
              }
            } catch (err) {
              return err;
            }
            processing.value = false;
          }
          return null;
        });
      }
      if (checkOutStatus.value === 0) {
        const defaultUser = user.find(({ isDefault }) => isDefault === '1');
        pay(defaultUser?.addressId ?? '').catch(() => {
          processing.value = false;
        });
      }
    };

    const newAddress = () => {
      // 清空点击进入过编辑状态的地址
      Object.assign(modelRef, {
        firstName: '',
        lastName: '',
        countryOrRegion: '',
        address: '',
        city: '',
        stateOrProvince: '',
        email: '',
        phoneNumber: '',
        zipCode: '',
      });
      checkOutStatus.value = 1;
    };

    const editAddress = (e: MouseEvent, addressId: string) => {
      // edit按钮在div中，避免点击edit的同时也点到div
      e.stopPropagation();
      window.scrollTo(0, 0);
      checkOutStatus.value = 3;
      const editUser = user.filter((item) => item.addressId === addressId)[0];
      Object.assign(modelRef, editUser);
    };

    const chooseAddress = async (addressId: string) => {
      // 选同一个 不请求
      if (
        addressId === user.filter((item) => item.isDefault === '1')[0].addressId
      ) {
        checkOutStatus.value = 0;
        return;
      }

      // 选择地址之后被选中的地址变成默认地址
      changeAddress(
        user.filter((item) => item.addressId === addressId)[0],
        addressId,
      ).then((res) => {
        if (res.success) {
          checkOutStatus.value = 0;
          window.scrollTo(0, 0);
          getAddress().then((re) => {
            Object.assign(user, re.data);
          });
        } else {
          const modal = Alert.error(res.message);
          setTimeout(() => {
            modal.destroy();
          }, 2000);
        }
      });
    };

    const chosePaymentMode = (mode: PayMode) => {
      paymentMode.value = mode;
      if (mode === 0 || mode === 2) {
        disabled.value = false;
      }
    };

    onMounted(() => {
      // 游客选择转账支付登录成功之后要获取一下购物车才能提交
      if (route.query.f) {
        paymentMode.value = 2;
        disabled.value = false;
        dispatch('getCartItems');
      }
      if (Cookies.get('token') && state.cartStore.cartItems.length === 0) {
        dispatch('getCartItems');
      } else if (!Cookies.get('token') && state.cartNumber !== 0) {
        dispatch('getVisitorCart');
      }
    });

    return {
      loading,
      modelRef,
      rulesRef,
      validateInfos,
      onSubmit,
      checkOutStatus,
      user,
      editAddress,
      newAddress,
      chooseAddress,
      worldOptions,
      chooseUser,
      processing,
      stateOptions,
      onChange,
      placeholder,
      phoneNumber,
      t,
      paymentRef,
      paymentValidate,
      paymentValidateInfos,
      creditCardImages,
      ExclamationCircleOutlined,
      paymentMode,
      chosePaymentMode,
      disabled,
      isAgree,
      localStorage,
    };
  },
});
