import http from '@/utils/http';
import type * as Request from './interface/request';
import type * as Response from './interface/response';

const modulePath = '/pay';

/**
 * @description: 支付订单
 * @param {Request} data
 * @return {*}
 */
export function payOrder(data: Request.PayOrder) {
  return http.client<Response.PayOrder>(`${modulePath}/pay_order`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 游客支付订单
 * @param {Request} data
 * @return {*}
 */
export function VisitorPayOrder(data: Request.VisitorPayOrder) {
  return http.client<Response.PayOrder>(`${modulePath}/visitor_pay_order`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 支付成功
 * @param {*}
 * @return {*}
 */

export function paySuccess(params: Request.PaySuccess) {
  return http.client<Response.IsSuccess>(`${modulePath}/pay_success`, {
    params,
  });
}

/**
 * @description: 游客信用卡支付成功
 * @param {string} orderNumber
 * @return {*}
 */
export function visitorCardPaySuccess(orderNumber: string, userId: number) {
  return http.client<Response.IsSuccess>(`${modulePath}/visitor_credit_card_pay_success?orderNumber=${orderNumber}&userId=${userId}`);
}

/**
 * @description: 信用卡支付成功
 * @param {string} orderNumber
 * @return {*}
 */
export function cardPaySuccess(orderNumber: string) {
  return http.client<Response.IsSuccess>(`${modulePath}/credit_card_pay_success?orderNumber=${orderNumber}`);
}

/**
 * @description: 信用卡支付失败，取消订单
 * @param {string} orderNumber
 * @return {*}
 */
export function cardPayCancel(orderNumber: string) {
  return http.client<Response.IsSuccess>(`${modulePath}/credit_card_pay_cancel?orderNumber=${orderNumber}`);
}

/**
 * @description: checkout 提交待支付订单
 */
export function addTransferOrder(data: Request.OrderNumber) {
  return http.client<Response.IsSuccess>(`${modulePath}/transfer_pay_order`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 游客信用卡支付失败，取消订单
 * @param {string} orderNumber
 * @return {*}
 */
export function VisitorCardPayCancel(orderNumber: string) {
  return http.client<Response.IsSuccess>(`${modulePath}/visitor_credit_card_pay_cancel?orderNumber=${orderNumber}`);
}

export default {};
